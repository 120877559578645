/* * * * * * *
 * This file is generated by scripts/generate-global-css.mjs.
 * Any customization will eventually be overwritten.
 * * * * * * */

/** Needed for the cdk overlay */
@import "@angular/cdk/overlay-prebuilt.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@custom-variant dark (&:where(.dark, .dark *));

:root {
  /* * * * * * *
   * WebSCU defaults
   * * * * * * */

  --background: 60, 11%, 95%;
  --foreground: 240, 100%, 8%;
  --primary: 191, 100%, 29%;
  --primary-foreground: 0, 0%, 100%;
  --accent: 163, 100%, 91%;
  --accent-foreground: 185, 100%, 17%;
  --ring: 205, 84%, 50%;
}

.dark {
  --background: 240, 100%, 8%;
  --foreground: 0, 0%, 100%;
  --primary: 180, 100%, 40%;
  --primary-foreground: 240, 100%, 8%;
  --accent: 207, 100%, 11%;
  --accent-foreground: 164, 100%, 50%;
  --ring: 205, 84%, 50%;
}

/* * * * * * *
 * Brand ville colors
 * * * * * * */
:root {
  --color-blue: #0087be;
  --color-blue-bold: #00e6dc;
  --color-blue-dark: #00557c;
  --color-blue-elevation-1: #23233c;
  --color-blue-elevation-2: #37374d;
  --color-blue-deep-8: #ebebee;
  --color-blue-deep-10: #e5e5e9;
  --color-blue-deep-20: #ccccd4;
  --color-blue-deep-30: #b3b3be;
  --color-blue-deep-40: #9999a9;
  --color-blue-deep-50: #7d8099;
  --color-blue-deep-55: #737389;
  --color-blue-deep-60: #66667e;
  --color-blue-deep-70: #4c4c68;
  --color-blue-deep-80: #333353;
  --color-blue-deep-85: #262648;
  --color-blue-deep: #000028;
  --color-blue-feedback-dark: #007eb1;
  --color-blue-feedback-light: #00bedc;
  --color-blue-focus-20: #d0e9fb;
  --color-blue-focus-30: #062c63;
  --color-blue-focus: #1491eb;
  --color-blue-interactive: #007993;
  --color-blue-soft: #00bedc;
  --color-coral-interactive-8: #001034;
  --color-coral-interactive-12: #00183b;
  --color-coral-interactive-20: #002949;
  --color-coral-interactive-50: #007082;
  --color-coral-interactive-80: #00a3ab;
  --color-coral-interactive: #00cccc;
  --color-current: currentColor;
  --color-cyan-interactive: #ebf7f8;
  --color-green: #00af8e;
  --color-green-bold-18: #d1fff2;
  --color-green-bold-60: #66ffd5;
  --color-green-bold-12db: #001f39;
  --color-green-bold-45db: #007369;
  --color-green-bold-55db: #008c78;
  --color-green-bold-60db: #00997f;
  --color-green-bold-65db: #00a686;
  --color-green-bold-90db: #00e5aa;
  --color-green-bold: #00ffb9;
  --color-green-dark: #00646e;
  --color-green-feedback-dark: #01d65a;
  --color-green-feedback-light: #01893a;
  --color-green-feedback-text: #018136;
  --color-green-light-40: #c5ffef;
  --color-green-light: #62eec7;
  --color-green-soft: #00d7a0;
  --color-orange-feedback-dark: #ff9000;
  --color-orange-feedback-light: #e96401;
  --color-petrol: #009999;
  --color-purple: #805cff;
  --color-purple-dark: #553ba3;
  --color-purple-soft: #b4a8ff;
  --color-red-dark: #331131;
  --color-red-feedback-dark: #ff2640;
  --color-red-feedback-dark-text: #ff7687;
  --color-red-feedback-light: #d72339;
  --color-red-feedback-light-text: #b81e31;
  --color-red-light: #fcccd7;
  --color-red-data-400: #ff6779;
  --color-red-data-900: #990000;
  --color-sand-bright: #dfdfd9;
  --color-sand-dark: #aaaa96;
  --color-sand-light: #f3f3f0;
  --color-sand-soft: #c5c5b8;
  --color-sand-elevation-1: #f3f3f0;
  --color-sand-elevation-2: #e8e8e3;
  --color-teal-64: #5c9095;
  --color-teal-74: #427e84;
  --color-teal-80: #33747a;
  --color-teal-84: #296d74;
  --color-teal-90: #196269;
  --color-teal: #005159;
  --color-transparent: transparent;
  --color-white: #ffffff;
  --color-yellow-dark: #4b433a;
  --color-yellow-feedback-dark: #ffd732;
  --color-yellow-feedback-light: #e9c32a;
  --color-yellow-light: #fff3c6;
}
