@import "ngx-toastr/toastr";
@import "@angular/cdk/overlay-prebuilt.css";
/* FIXME: Remove this once tailwind transition is done. */
@import "../packages/ui/src/styles.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@custom-variant dark (&:where(.dark, .dark *));

/* FIXME: Remove them once tailwind migration is done. */
:root {
  --dark-bg-color: #23233c;
  --highlight-color: #00ffb9;
  --bg-gray-color: #f3f3f0;
  --primary-black-color: #000028;
  --btn-hover-color: #d1fff2;
  --btn-txt-hover-color: #005159;
  --btn-active-color: #196269;
  --grid-header-color: #66667e;
  --hover-gray-color: #e8e8e3;
  --border-gray-color: #4c4c68;
  --btn-primary-color: #007993;
  --btn-disable-color: #b3b3be;
  --btn-delete-color: #d72339;
  --text-border-color: #ccccd4;
  --white-color: #ffffff;
  --text-bgv-color: #ebf7f8;
  --main-padding: 22px;
  --textbox-height: 44px;
  --content-font-size: 14px;
  --large-font-size: 16px;
  --small-font-size: 12px;
  --default-message-gray-color: #66667e80;
  --main-header-height: 44px;
  --error-label-color: #b81e31;
  --mat-select-panel-background-color: white;
}

@font-face {
  font-family: "Siemens Sans";
  src: url("/assets/font/SiemensSansProVF_W_Wght.woff2") format("woff2");
  font-style: normal;
}

@font-face {
  font-family: "Siemens Sans";
  src: url("/assets/font/SiemensSansProVF_Italics_W_Wght.woff2") format("woff2");
  font-style: italic;
}

* {
  font-family: "Siemens Sans";
  font-synthesis: none !important; /* fix font weight synthesization on mac. */
}

.spinner-color {
  color: var(--btn-primary-color);
}
.hc .highcharts-container {
  height: 100% !important;
  width: auto !important;
}
.hc svg {
  width: auto;
  height: 100% !important;
}

::-webkit-scrollbar {
  width: 6px;
  height: 0.75vh;
}

::-webkit-scrollbar-thumb {
  background: var(--color-blue-deep-70);
  border-radius: 3px;
}

.dark::-webkit-scrollbar-thumb {
  background: #b3b3be;
}

.attributes-panel-class {
  /* margin-top: 30px !important; */
  background-color: white;
  box-shadow: none !important;
  border-radius: 0px !important;
  border: 1px solid var(--btn-txt-hover-color);
}
.setting-panel-class {
  background-color: white;
  border-radius: 0px !important;
  border: 1px solid var(--btn-txt-hover-color);
  position: absolute;
  left: 10px;
}
.relay-setting-panel-class {
  background-color: white;
  box-shadow: none !important;
  border-radius: 0px !important;
  border: 1px solid var(--btn-txt-hover-color);
  position: absolute;
  left: 10px;
  min-width: 10px !important;
  width: 80px !important;
}
.text-readonly {
  width: 100% !important;
  height: 2.75rem !important;
  border: 1px solid var(--text-border-color) !important;
  border-radius: 0.125rem !important;
  color: var(--border-gray-color) !important;
  padding-left: 0.625rem !important;
  background-color: white !important;
}

.text-readonly:focus-visible {
  outline: none;
}

.ng-tooltip {
  background-color: black !important;
  color: #ffffff;
  font-size: var(--small-font-size) !important;
  font-family: "Siemens Sans" !important;
  font-weight: 400 !important;
  border-radius: 4px !important;
  position: absolute;
  max-width: 150px;
  text-align: center;
  padding: 3px 8px;
  z-index: 1000;
  opacity: 0;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent var(--primary-black-color) transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent var(--primary-black-color);
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent var(--primary-black-color) transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
}
